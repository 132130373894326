html {
  scroll-behavior: smooth;
}
canvas {
  width: 100vw;
  height: 100vh;
  position: fixed;
  opacity: 0.4;
}
#root {
  position: absolute;
  width: 100%;
  height: 100vh;
  pointer-events: none;
}
.main-container {
  position: absolute;
  left: 50%;
  top: 50%;
}
.scroll-container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100vh;
  h1 {
    margin-top: 50px;
    text-align: center;
    color: white;
    font-family: "Shadows Into Light", cursive;
  }

  .section {
    &__top {
      min-height: 100vh;
      pointer-events: none;
      .logo-container {
        margin-top: 60px;
        z-index: 999;
        position: relative;
        text-align: center;
        align-self: center;

        .logo_image {
          opacity: 0.9;
          text-align: center;
          width: 160px;
          height: 160px;
          margin: auto;
          pointer-events: painted;

          background-image: url("./images/logoo.png");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;

          &:hover {
            animation: shake 1.2s;
            /* When the animation is finished, start again */
            animation-iteration-count: infinite;
          }
        }
        .menu {
          height: 100%;
          width: 100%;
          margin: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 15vh;
          ul {
            list-style: none;
            padding: 0;
            li {
              a {
                font-size: 1.5rem;
                pointer-events: all;
                text-decoration: none;
                color: white;
                font-family: "Shadows Into Light", cursive;
              }
              transition: ease-in-out;
              transition-duration: 0.3s;
              &:hover {
                transform: perspective(30px) translate3d(0px, -2px, 2.5px)
                  rotateX(-2deg) scale3d(1, 0.9, 1) translateY(5px);

              }
            }
          }
        }
      }
    }
    &__bottom {
      margin: auto;
      scroll-behavior: smooth;
      width: 85%;
      margin: auto;
      display: flex;
      flex-direction: column;
      text-align: center;
      pointer-events: all;

      .group_image {

        margin: 20px auto;
        border-radius: 50%;
        opacity: 0.8;
        border: 1px solid white;
        min-width: 130px;
        min-height: 130px;
        text-align: center;

        background-image: url("./images/group.jpg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        transition: ease-in-out;
        transition-duration: 1s;
        pointer-events: all;
        &:hover {
          opacity: 1;
          transform: scale(2.5) rotate(720deg);
        }
      }
      h2 {
        color: white;
      }
      p {
        text-align: center;
        pointer-events: none;
        color: white;
        font-family: "Noto sans", cursive;
        @media only screen and (max-width: 600px) {
          font-size: 11px;
        }
        &.bold {
          font-weight: bold;
        }
        &.cursive {
          font-style: italic;
        }
      }

      &.impressum {
        height: 100vh;
        padding-bottom: 100px;
        h2 {
          font-family: "Shadows Into Light", cursive;
        }
        a {
          color: white;
          pointer-events: all;
        }
        p {
          font-family: "Noto sans", cursive;
        }
      }
      .ticket-container {
        pointer-events: all;
        display: inline-block;
        width: 100%;
        height: 140vh;
        @media only screen and (max-width: 900px) {
          height: 190vh;
        }


        position: relative;
        iframe {
          position: absolute;
          height: 100%;
          pointer-events: all;
          left: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
      }
      .playlist-container {
        pointer-events: all;
        height: fit-content;

        min-height: 100vh;
        opacity: 0.9;
        position: relative;
        width: 100%;
        align-self: center;
        overflow: hidden;
        border-radius: 5px;
        iframe {
          pointer-events: all;
        }
      }
    }
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
